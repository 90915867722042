//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { emailSendEmail, emailSendEmailHtml } from '@/api/modular/system/emailManage'
import { AntdEditor } from '@/components'
// eslint-disable-next-line no-unused-vars
import { sysFileInfoUpload, sysFileInfoDownload } from '@/api/modular/system/fileManage'
export default {
  components: {
    AntdEditor
  },
  data () {
    return {
      editorContentText: '',
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage
      },
      confirmLoading: false,
      editorContent: '',
      form1: this.$form.createForm(this),
      form2: this.$form.createForm(this)
    }
  },
  methods: {
    tabsCallback (key) {
      if (key === '1') {
        // eslint-disable-next-line no-labels
        form1: this.$form.createForm(this)
        this.form2.resetFields()
        this.editor.txt.clear()
      }
      if (key === '2') {
        // eslint-disable-next-line no-labels
        form2: this.$form.createForm(this)
        this.form1.resetFields()
      }
    },
    /**
     * 編輯器回調上傳及回傳圖片url
     */
    editorUploadImage (files, insert) {
      const formData = new FormData()
      files.forEach(file => {
        formData.append('file', file)
      })
      sysFileInfoUpload(formData).then((res) => {
        if (res.success) {
          insert(process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + res.data)
        } else {
          this.$message.error('編輯器上傳圖片失敗：' + res.message)
        }
      })
    },
    getEditor (editor) {
      this.editor = editor
    },
    changeEditor (html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    /**
     * 發送郵件
     */
    handleSubmit1 () {
      const { form1: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          emailSendEmail(values).then((res) => {
            if (res.success) {
              this.$message.success('發送成功')
              this.confirmLoading = false
              this.form1.resetFields()
            } else {
              this.$message.error('發送失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * 發送Html郵件
     */
    handleSubmit2 () {
      const { form2: { validateFields } } = this
      // eslint-disable-next-line eqeqeq
      if (this.editorContent == '') {
        this.$message.error('請填寫郵件內容')
        return
      }
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          values.content = this.editorContent
          emailSendEmailHtml(values).then((res) => {
            if (res.success) {
              this.$message.success('發送成功')
              this.confirmLoading = false
              this.editor.txt.clear()
              this.form2.resetFields()
            } else {
              this.$message.error('發送失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    }

  }
}
